<template>
  <div class="header">
    <div class="logo">
      <a href="/"><img src="@/assets/logo.png" /></a>
    </div>

    <!-- <el-menu
      :default-active="$route.path"
      class="el-menu-demo"
      text-color="#16181a"
      active-text-color="#0052d9"
      mode="horizontal"
      @select="handleSelect"
      router
    >
      <el-menu-item index="/home">活动</el-menu-item>
      <el-menu-item index="/app">应用</el-menu-item>
      <el-menu-item index="/live">直播</el-menu-item>
    </el-menu> -->

    <div class="nav-top">

        <!-- <div class="pls-nav-top-links">
          <a
            href=""
            class="pls-nav-top-link"
            >文档</a
          ><a
            href=""
            class="pls-nav-top-link"
            >控制台</a
          >
        </div> -->

      <div class="nav" v-if="userinfo.name">
        <el-avatar :src="userinfo.img"></el-avatar>
        <el-col :span="12">
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              {{ userinfo.name
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-s-tools" command="c"
                >修改资料</el-dropdown-item
              >
              <el-dropdown-item icon="el-icon-s-unfold" command="a"
                >退出登录</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </div>
      <div class="" v-if="!userinfo.name">
        <a href="#" class="top-btn">注册/登录</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["userinfo"]),
  },
  created() {
    const storedExpirationTime = localStorage.getItem("expirationTime");
    if (storedExpirationTime && new Date().getTime() > storedExpirationTime) {
      // 数据已过期，进行相应处理
      localStorage.removeItem("user");
      localStorage.removeItem("expirationTime");
      this.deleteUser();
      this.$message.error("登录已过期");

      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    ...mapMutations("user", ["deleteUser"]),

    handleCommand(command) {
      if (command === "a") {
        localStorage.removeItem("user");
        this.$message.error("已退出登录状态");
        this.deleteUser();

        this.$router.push({ path: "/login" });
      }

      if (command === "c") {
        this.$router.push({ path: "/edit" });
      }
    },
    handleSelect(){

    }
  },
};
</script>

<style scoped lang="less">
.header {
  position: absolute;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1000;
  background: #fff;
  margin: 0 auto;
  display: flex;
  border-bottom: solid 1px #e6e6e6;
  .logo {
    padding: 0 10px;
  }
  .logo img {
    padding: 17px 40px 0px 25px;
    height: 25px;
  }
  .el-menu.el-menu--horizontal {
    border-bottom: solid 0px #ffffff;
  }

  .nav-top {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    white-space: nowrap;
    .nav {
      position: relative;
      display: flex;
      align-items: center;
    }
  }
}
.pls-nav * {
    -ms-overflow-style: none;
    scrollbar-width: none;
}


.pls-nav-top-links {
      display: flex;
    }
.pls-nav-top-link {
    display: block;
    margin-right: 24px;
    font-weight: 500;
    font-size: 14px;
    color: #16181a;
    line-height: 60px;
}


.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: hsla(0, 0%, 100%, 0.1);
}

.top-btn {
  display: block;
  box-sizing: border-box;
  height: 59px;
  padding: 0 20px;
  font-size: 14px;
  color: #fff;
  line-height: 60px;
  background-color: #0052d9;
  color: #fff;
  cursor: pointer;
}

.el-menu {
  border-right: solid 1px #e6e6e6;
  list-style: none;
  position: relative;
  margin: 0;
  padding-left: 0;
  background: hsla(0, 0%, 100%, 0.1);
}

// .header:hover {
//   background: #fff;
//   color: #1d2129;
// }

.el-dropdown-link {
  margin-right: 25px;
  margin-left: 10px;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.demonstration {
  display: block;
  color: #8492a6;
  font-size: 14px;
  margin-bottom: 20px;
}
</style>
